var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.maskBottom }, [
    _c("div", { class: _vm.$style.con }, [
      _c("p", [_vm._v("注册/登录" + _vm._s(_vm.text))]),
      _vm._v(" "),
      _c("p", [_vm._v("查看最新最热的优选设计")]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.buttonLine }, [
        _c(
          "a",
          {
            class: [_vm.$style.toRegister, "font-bold"],
            attrs: { href: _vm.PagePinliteRegister },
            on: {
              "!click": function ($event) {
                $event.preventDefault()
                return _vm.handleClick("register")
              },
            },
          },
          [_vm._v("注册")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            class: [_vm.$style.toLogin, "font-bold"],
            attrs: { href: _vm.PagePinliteLogin },
            on: {
              "!click": function ($event) {
                $event.preventDefault()
                return _vm.handleClick("login")
              },
            },
          },
          [_vm._v("登录")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }