<template>
  <div :class="$style.maskBottom">
    <div :class="$style.con">
      <p>注册/登录{{text}}</p>
      <p>查看最新最热的优选设计</p>
      <div :class="$style.buttonLine">
        <a :class="[$style.toRegister,'font-bold']"  @click.prevent.capture="handleClick('register')" :href=PagePinliteRegister >注册</a>
        <a :class="[$style.toLogin,'font-bold']" @click.prevent.capture="handleClick('login')" :href=PagePinliteLogin >登录</a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
    import {
        goPinliteRegister,
        goPinliteLogin,
        PageLogin,
        PagePinliteTupian,
        getPinliteLogin,
        getPinliteRegister,
        BigBigWork
    } from "bbw-common";
  /** 未登录底部遮罩 */
export default {
  name: "MaskBottom",
      props:{
          /** 是否打开登录弹窗 */
          showRegisterDialog:{
              type:Boolean,
              required:false,
              default: false
          },
          redirect:{
              type:String,
              required: false,
              default:""
          },
        /** 是否为sem_h */
        isSemH:{
          type:Boolean,
          required:false,
          default: false
        }
      },
      data(){
          return {
              PageLogin,
              PagePinliteRegister: getPinliteRegister(this.redirect || "/tupian"),
              PagePinliteLogin: getPinliteLogin(this.redirect || "/tupian")
          }
      },
  methods: {
      /** 点击处理 */
      handleClick( type ){
          /*if( type === 'login'){
            goPinliteLogin("/tupian")
          }else if ( type === 'register'){
            goPinliteRegister( "/tupian" );
          }*/
          if(this.showRegisterDialog){
              BigBigWork.emit('OpenDialogLogin')
          }else{
            if( type === 'login'){
                goPinliteLogin("/tupian")
            }else if ( type === 'register'){
                goPinliteRegister( "/tupian" );
            }
          }
      },
    register() {
        goPinliteRegister( "/tupian" );
    },
    login() {
        goPinliteLogin( "/tupian" );
    }
  },
    computed:{
      text(){
        if(this.isSemH){
          return `大作`
        }else{
          return `Pinlite极速版`
        }
      }
    }

};
</script>
<style lang="scss" src="./MaskBottom.scss" module></style>
